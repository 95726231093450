<template>
        <el-dialog title="Vanzari" :visible.sync="showPopup" class="my-dialog-class" >
        <el-form label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject'  ref='my-form' @submit.prevent="save" v-loading="loadingVisible" >
            <el-row :gutter="15" >
                
                <el-col :md='8'>
                    <el-form-item label='Ip Public' >
                        <el-input  class='full-width' v-model='selectedObject.IpPublic' />
                    </el-form-item>
                </el-col>
                <el-col :md='8'>
                    <el-form-item label='Ip Local' >
                        <el-input  class='full-width' v-model='selectedObject.IpLocal' />
                    </el-form-item>
                </el-col>
                <el-col :md='8'>
                    <el-form-item label='Id Statie' >
                        <el-input  class='full-width' v-model='selectedObject.IdStatie' />
                    </el-form-item>
                </el-col>
                <el-col :md='8'>
                    <el-form-item label='Data Reala' >
                        <el-date-picker  class='full-width' v-model='selectedObject.DataReala' type='datetime' value-format='yyyy-MM-dd HH:mm:ss' format='dd.MM.yyyy HH:mm' />
                    </el-form-item>
                </el-col>
                <el-col :md='8'>
                    <el-form-item label='Data Economica' >
                        <el-date-picker  class='full-width' v-model='selectedObject.DataEconomica' type='date' value-format='yyyy-MM-dd' format='dd.MM.yyyy' />
                    </el-form-item>
                </el-col>
                <el-col :md='8'>
                    <el-form-item label='Sectie' >
                        <el-input  class='full-width' v-model='selectedObject.Sectie' />
                    </el-form-item>
                </el-col>
                <el-col :md='8'>
                    <el-form-item label='Produs' >
                        <el-input  class='full-width' v-model='selectedObject.Produs' />
                    </el-form-item>
                </el-col>
                <el-col :md='8'>
                    <el-form-item label='Cantitate' >
                        <el-input-number class='full-width' v-model='selectedObject.Cantitate' :precision='2' :max='999999'></el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :md='8'>
                    <el-form-item label='Pret' >
                        <el-input-number class='full-width' v-model='selectedObject.Pret' :precision='2' :max='999999'></el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :md='8'>
                    <el-form-item label='Client' >
                        <el-input  class='full-width' v-model='selectedObject.Client' />
                    </el-form-item>
                </el-col>
                <el-col :md='8'>
                    <el-form-item label='Telefon' >
                        <el-input  class='full-width' v-model='selectedObject.Telefon' />
                    </el-form-item>
                </el-col>
                <el-col :md='8'>
                    <el-form-item label='ModPlata' >
                        <el-input  class='full-width' v-model='selectedObject.ModPlata' />
                    </el-form-item>
                </el-col>
                <el-col :md='8'>
                    <el-form-item label='Utilizator' >
                        <el-input  class='full-width' v-model='selectedObject.Utilizator' />
                    </el-form-item>
                </el-col>
                <el-col :md='8'>
                    <el-form-item label='Nume Masa' >
                        <el-input  class='full-width' v-model='selectedObject.NumeMasa' />
                    </el-form-item>
                </el-col>            
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer" >
            <el-button @click="showPopup=false"     > Renunta  </el-button>
            <el-button type="primary" @click="save" > Salveaza </el-button>
        </span>
    </el-dialog>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage';

    export default {
        name: "Vanzari_dialog",
        extends: BasePage,
        data () {
            return {
                baseUrl :'',
                showPopup: false,
                mode: 'add',
                selectedObject: {
                    IpPublic: '' , IpLocal: '' , IdStatie: '' , DataReala: '' , DataEconomica: '' , Sectie: '' , Produs: '' , Cantitate: '' , Pret: '' , Client: '' , Telefon: '' , ModPlata: '' , Utilizator: '' , ModPlata: '' ,                 },
                Info:{
                                    },
                rules: {
                    //   Nume: [ { required: true, message: "Campul este obligatoriu" } ]
                    IpPublic: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    IpLocal: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    IdStatie: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    DataReala: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    DataEconomica: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Sectie: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Produs: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Cantitate: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Pret: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Client: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Telefon: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    ModPlata: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Utilizator: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    ModPlata: [ { required: true, message: 'Campul este obligatoriu' } ], 
                }
            }
        },
        methods: {
            show_me: async function( id ) {
                this.showPopup        = true;
                if( id == null )
                {
                    this.mode = "add";
                }
                else
                {
                    this.mode             = "edit";
                    var            result = await this.post("vanzari/get_info_item_dialog", { id: id } );
                    this.selectedObject   = result.Item;
                }
            },
            async get_info(){
                var response = await this.post("vanzari/get_info_for_dialog" );
                            },
                        save: async function() {
                this.$refs['my-form'].validate( async(valid) => {
                    if (valid)
                    {
                        await this.post("vanzari/save", { mode: this.mode, object: this.selectedObject } );
                        this.showPopup = false;
                        this.$emit("save");
                    }
                });
            }
        },
        mounted: function() {
            this.baseUrl = settings.BASE_URL;
            this.get_info();
        }
    };
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }

</style>