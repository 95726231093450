<template>
    <base-page>
        <titlu-pagina Titlu="Vanzari" @on_add_clicked="show_dialog()" :AdaugaVisible='true' />


        <el-card style='margin:5px 0px 5px 0px'>
            <div slot="header" class="clearfix">
                <strong> Filtre </strong>
            </div>
            <div class="filtre">
                <el-form @submit.prevent.native='refresh_info()'>
                    <el-row :gutter="20">
                        
                        <el-col :md='3'>
                            <el-form-item label='Branding' >
                                <el-input v-model='Filters.Branding' />
                            </el-form-item>
                        </el-col>

                        <el-col :md='3'>
                            <el-form-item label='Ip Public' >
                                <el-input v-model='Filters.IpPublic' />
                            </el-form-item>
                        </el-col>
                        <el-col :md='3'>
                            <el-form-item label='Ip Local' >
                                <el-input v-model='Filters.IpLocal' />
                            </el-form-item>
                        </el-col>
                        <el-col :md='3'>
                            <el-form-item label='Id Statie' >
                                <el-input v-model='Filters.IdStatie' />
                            </el-form-item>
                        </el-col>
                        <el-col :md='6'>
                            <el-form-item label='Data Reala' >
                                <el-date-picker v-model='Filters.DataReala' type='datetimerange' value-format='yyyy-MM-dd HH:mm:ss' format='dd.MM.yyyy HH:mm' />
                            </el-form-item>
                        </el-col>
                        <el-col :md='6'>
                            <el-form-item label='Data Economica' >
                                <el-date-picker v-model='Filters.DataEconomica' type='daterange' value-format='yyyy-MM-dd' format='dd.MM.yyyy' />
                            </el-form-item>
                        </el-col>
                        <el-col :md='3'>
                            <el-form-item label='Sectie' >
                                <el-input v-model='Filters.Sectie' />
                            </el-form-item>
                        </el-col>
                        <el-col :md='4'>
                            <el-form-item label='Produs' >
                                <el-input v-model='Filters.Produs' />
                            </el-form-item>
                        </el-col>
                        <el-col :md='3'>
                            <el-form-item label='Client' >
                                <el-input v-model='Filters.Client' />
                            </el-form-item>
                        </el-col>
                        <el-col :md='3'>
                            <el-form-item label='Telefon' >
                                <el-input v-model='Filters.Telefon' />
                            </el-form-item>
                        </el-col>
                        <el-col :md='3'>
                            <el-form-item label='Utilizator' >
                                <el-input v-model='Filters.Utilizator' />
                            </el-form-item>
                        </el-col>
                        <el-col :md='3'>
                            <el-form-item label='Nume Masa' >
                                <el-input v-model='Filters.NumeMasa' />
                            </el-form-item>
                        </el-col>    
                        <el-col :md='4'>
                            <el-form-item label='Mod de plata' >
                                <el-input v-model='Filters.ModPlata' />
                            </el-form-item>
                        </el-col>                        
                        <el-col :md='24' >
                            <el-button type='primary' native-type='submit' @click='refresh_info()'> Aplica </el-button>
                            TOTAL {{Total|moneyformat}} Lei
                        </el-col>

                    </el-row>
                </el-form>
            </div>
        </el-card>

        <el-table :data="Results" >
            <el-table-column prop='Branding' label='Branding' width='200'></el-table-column>
            <el-table-column prop='IpPublic' label='IP Public' width='120'></el-table-column>
            <el-table-column prop='IpLocal' label='Ip Local'  width='120'></el-table-column>
            <el-table-column prop='IdStatie' label='Id Statie'></el-table-column>
            <el-table-column label='Data Reala'  width='180'>
                <template slot-scope='scope'>
                    {{ scope.row.DataReala | momentformat('dd.MM.YYYY HH:mm:ss') }} 
                </template>
            </el-table-column>
            <el-table-column label='Data Ec' width='100'>
                <template slot-scope='scope'>
                    {{ scope.row.DataEconomica | momentformat('dd.MM.YYYY') }}
                </template>
            </el-table-column>
            <el-table-column prop='Sectie' label='Sectie'  width='100'></el-table-column>
            <el-table-column prop='Produs' label='Produs' width='250'></el-table-column>
            <el-table-column prop='Cantitate' label='Cant.' align='right' width='60'></el-table-column>
            <el-table-column label='Pret' align='right' width='120'>
                <template slot-scope='scope'>
                    {{ scope.row.Pret | moneyformat }} Lei
                </template>
            </el-table-column>
            <el-table-column prop='Client' label='Client' width='100'></el-table-column>
            <el-table-column prop='Telefon' label='Telefon' width='100'></el-table-column>
            <el-table-column prop='ModPlata' label='Mod Plata' width="300"></el-table-column>
            <el-table-column prop='Utilizator' label='Utilizator' width='100'></el-table-column>
            <el-table-column prop='NumeMasa' label='Masa' width='100'></el-table-column>
            <el-table-column fixed="right" label="Actiuni" width="100" >
                <template slot-scope="scope" >
                    <el-tooltip content="Modificare">
                        <el-button size='small' type="primary" icon="el-icon-edit"  circle @click="show_dialog(scope.row.Id)" />
                    </el-tooltip>
                    <el-tooltip content="Sterge" >
                        <el-button size='small' type="danger" icon="el-icon-delete" circle @click="delete_item(scope.row)" />
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination @size-change="refresh_info" @current-change= "refresh_info" :page-size.sync="PaginationInfo.PerPage" :current-page.sync="PaginationInfo.Page" :total="PaginationInfo.RowCount" layout="pager" />
        <Vanzari-dialog ref='dlg' @save="refresh_info()" />
    </base-page>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import Vanzari_dialog from '@/pages/vanzari/Vanzari_dialog.vue';
import TitluPagina from '@/widgets/TitluPagina';

export default {
    name: "vanzari",
    extends: BasePage,
    components: {
        'base-page': BasePage,
        'Vanzari-dialog': Vanzari_dialog,
        'titlu-pagina': TitluPagina
    },
    data () {
        return {
            Results: [],
            base_url: '',
            Info: { },
            Filters: { 
                Branding:'', IpPublic: '' , IpLocal: '' , IdStatie: '' , DataReala: '' , DataEconomica: '' , Sectie: '' , 
                Produs: '' , Client: '' , Telefon: '' , Utilizator: '' , NumeMasa: '' , ModPlata:''            
            },
            Total: 0,
            OrderBy: { },
            PaginationInfo: { Page: 1, PerPage: 200, RowCount: 0, PageSizes: [10, 25, 50, 100, 200] },
        }
    },
    methods: {

        async get_info() {
            var response        = await this.post("vanzari/get_info" );
            this.refresh_info();
        },

        async refresh_info() {
            var response        = await this.post("vanzari/index", { Filters: this.Filters, OrderBy: this.OrderBy, PaginationInfo: this.PaginationInfo } );
            this.Results        = response.Results;
            this.Total          = response.Total;
            this.PaginationInfo = response.PaginationInfo;
            //
            this.select_menu_item('vanzari');
        },

        reset() {
            this.Filters = {
                Branding:'', IpPublic: '' , IpLocal: '' , IdStatie: '' , DataReala: '' , DataEconomica: '' , 
                Sectie: '' , Produs: '' , Client: '' , Telefon: '' , Utilizator: '' , NumeMasa: '' , ModPlata:''
            };
            this.refresh_info();
        },


        async delete_item( item ) {
            var confirm =  await this.$confirm(`Sunteti sigur ?`, 'Warning');
            if( confirm )
            {
                await this.post("vanzari/delete_item", { id: item.Id } );
                this.refresh_info();
            }
        },

        show_dialog(id){
            this.$refs.dlg.show_me(id);
        }
    },
    mounted(){
        this.base_url = settings.BASE_URL;
        this.get_info();
    }
};
</script>

<style lang="less" scoped>

    .top50{
        margin-top: 20px;
    }

    .filtre{
        .el-input-number
        {
            width: 100% !important;
        }
    }

</style>
